

/* We set the size of the picture */
.top-cart-item-image img {
    width:auto!important;
    margin-left:auto;
    margin-right:auto;
}
.top-cart-item .top-cart-item-desc-title {
    width:max-content;
}

/* We ensure some space between title and quantity */
.top-cart-item .top-cart-item-desc-title a {
    margin-right:5px;
}

/* We ensure that price and capacity are on the same line */
.top-cart-item .top-cart-item-price {
    white-space: nowrap;
}

/* We ensure some space between price and capacity */
.top-cart-item .top-cart-item-capacity > span {
    margin-left:5px;
}

/* we ensure that quantity text is on one line */
.top-cart-item .top-cart-item-quantity {
    white-space: nowrap;
}

/* We set some space and separator with remove button */
.top-cart-item .top-cart-item-buttons {
    margin-left: 5px;
    border-left: 1px solid #eee;
    padding-left: 5px;
}

/* We set size of the remove button */
.top-cart-item .top-cart-item-buttons button.remove {
    padding: 0 8px;
    margin:0px;
    height: 36px;
    line-height: 38px;
}

/* We set size of the remove button */
.top-cart-item .top-cart-item-buttons button.remove > i {
    font-size: 18px;
}


